
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { checkPropTypes } from 'prop-types';
import Markdown from 'markdown-to-jsx';

const convert = require('color-convert');


const getDate = (time) => {
  const _arr = time.split('-'); // 2020-09-28
  const year = _arr[0];
  const month = _arr[1] - 1;
  const day = _arr[2];
  return new Date(year, month, day, 12);
};

const getTarifColor = (tarifId, tarifs) => {
  const tarifColor = tarifs.find((item) => item.strapiId === tarifId).color;
  return tarifColor;
};

const formatTime = (time) => {
  const datetime = new Date(`1995-12-17T${time}`);

  const heure = datetime.getHours();
  const minute = datetime.getMinutes() !== 0 ? datetime.getMinutes() : '';
  const formatted = `${heure}h${minute}`;

  return formatted;
};

function Planner({
  tarifs, color, detailsTarif, seances,
}) {
  const data = useStaticQuery(graphql`
    query plannerEnfantsQuery {
      allStrapiTarifs {
          nodes {
            id
            nom
            strapiId
            tarifs {
              nom
              id
              valeur
            }
          }
      }
      strapiCalendrierSessions {
        id
        nom
        calendrier {
          id
          nom
          debut
          fin
        }
      }
    }
  `);


  const { allStrapiTarifs, strapiCalendrierSessions } = data;
  const rgbCode = convert.hex.rgb(color);
  const baseColor = convert.rgb.hsl(rgbCode);
  // add color level

  const tarifColors = allStrapiTarifs.nodes.map((tarif, index) => {
    const color = [baseColor[0], baseColor[1], baseColor[2] * (1 - index * 0.15)];
    tarif.color = color;
    return tarif;
  });
  ('tarifs : ', tarifs);
  const plannerTarifs = tarifColors.filter((item) => {
    let isPresent = false;
    tarifs.forEach((tarif) => {
      if (tarif === item.strapiId) {
        isPresent = true;
      }
    });
    return isPresent;
  });
  (plannerTarifs);

  const seancesParArtiste = seances.reduce((acc, currentSeance) => {
    if (!acc.hasOwnProperty(currentSeance.description)) {
      acc[currentSeance.description] = {
        nom: currentSeance.description,
        seances: [],
      };
    }
    (currentSeance);
    currentSeance.color = getTarifColor(currentSeance.tarif.id, tarifColors);
    acc[currentSeance.description].seances.push(currentSeance);
    return acc;
  }, {});
  ('SeanceParArtiste : ', seancesParArtiste);

  const tarifStyle = {
    border: '5px',
    borderColor: `hsl(${baseColor[0]}deg ${baseColor[1]}% ${baseColor[2]}%)`,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',

  };
  const calendrierStyle = {
    border: '5px',
    borderColor: `hsl(${baseColor[0]}deg ${baseColor[1]}% ${baseColor[2]}%)`,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',

  };
  const trStyle = {
    border: '0px',
    textAlign: 'left',
  };
  return (


    <div className="container">

      <div className="row">

        <div className="col-md-6">
          <h3>Horaires</h3>
          <Horaires seances={seances} />
          <div>
            <Markdown>
              {detailsTarif || ''}
            </Markdown>
          </div>

        </div>
        <div className="col-md-6">
          <div className="row table-responsive">
            <h3>Tarifs</h3>
            <table className="table table-sm" style={tarifStyle}>
              <thead style={trStyle}>
                <tr style={trStyle}>
                  <th scope="col">#</th>
                  {plannerTarifs[0].tarifs.map((formule, index) => (<th key={`th_${index}`}>{formule.nom}</th>
                  ))}

                </tr>
              </thead>
              <tbody style={trStyle}>
                {plannerTarifs.map((tarif, index) => {
                  // hsl(343deg 68% 83%)
                  const tarifColor = `hsl(${tarif.color[0]}deg ${tarif.color[1]}% ${tarif.color[2]}%)`;
                  return (
                    <tr style={{ backgroundColor: tarifColor }} key={`tr_${index}`}>
                      <th scope="row">{tarif.nom}</th>
                      {tarif.tarifs.map((formule, index) => (
                        <td key={`tdd_${index}`}>
                          {formule.valeur}
                          {' '}
                          €
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="row table-responsive">
            <h3>Calendrier</h3>
            <table className="table table-sm " style={calendrierStyle}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {strapiCalendrierSessions.calendrier.map((formule, index) => (<th scope="col" key={`tch_${index}`}>{formule.nom}</th>))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Début des cours</th>
                  {strapiCalendrierSessions.calendrier.map((formule, index) => {
                    const options = {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    };
                    const objDate = getDate(formule.debut);
                    const strDate = objDate.toLocaleDateString('fr-FR', options);
                    return (<td key={`tdd_${index}`}>{strDate}</td>);
                  })}
                </tr>
                <tr>
                  <th scope="row">Fin des cours</th>
                  {strapiCalendrierSessions.calendrier.map((formule, index) => {
                    const options = {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    };
                    const objDate = getDate(formule.fin);
                    const strDate = objDate.toLocaleDateString('fr-FR', options);
                    return (<td key={`tdf_${index}`}>{strDate}</td>);
                  })}
                </tr>
              </tbody>
            </table>

          </div>

        </div>
      </div>

    </div>

  );
}


const Horaires = ({ seances }) => {
  const seancesParJour = seances.reduce((acc, currentSeance) => {
    if (!acc.hasOwnProperty(currentSeance.jour)) {
      acc[currentSeance.jour] = [];
    }
    acc[currentSeance.jour].push(currentSeance);
    return acc;
  }, {});

  return (
    <>

      {Object.keys(seancesParJour).map((jour, index) => {
        const seances = seancesParJour[jour];
        const tarifColor = `hsl(${seances[1].color[0]}deg ${seances[0].color[1]}% ${seances[0].color[2]}%)`;
        return (

          <div className="m-1" style={{ textAlign: 'center' }} key={`spj_${index}`}>
            <div style={{ backgroundColor: tarifColor, textAlign: 'center' }}><strong>{jour}</strong></div>

            <div>
              { seances.map((seance, index) => (
                <div key={`s_${index}`}>
                  <strong>
                    {formatTime(seance.heure_debut)}
                    -
                    {formatTime(seance.heure_fin)}

                  </strong>
                  {' : '}
                  {seance.description}
                </div>

              ))}
            </div>

          </div>

        );
      })}
    </>
  );
};


export default Planner;
